/*
 * @Author: jianghaoran01 13478707150@163.com
 * @Date: 2022-12-26 14:22:46
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-30 16:35:18
 * @FilePath: \application-examinee-frontend\src\layout\primary-layout\index.tsx
 * @Description: 基础布局
 */

import React from 'react';

import {Layout} from 'antd';
import LayoutHeader from '../layout-header';
import LayoutMain from '../layout-main';

import styles from './index.module.less';

const PrimaryLayout = () => {

    return (
        <Layout className={styles.container}>
            <LayoutHeader />
            <div className={styles.content} id='layout-main'>
                <LayoutMain />
            </div>
        </Layout>
    );
};

export default PrimaryLayout;
