/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-28 10:39:03
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-28 16:08:53
 * @FilePath: \application-examinee-frontend\src\components\cus-input\index.tsx
 * @Description: 定制化输入框
 */

import React from 'react';
import cls from 'classnames';

import { Button, ButtonProps } from 'antd';

import styles from './index.module.less';

interface CusButtonProps extends ButtonProps {}

const CusButton: React.FC<CusButtonProps> = ({
    className,
    ...props
}) => {
    const prefix = cls(styles.container, className);

    return <Button className={prefix} {...props} />;
};

export default CusButton;