/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-08 13:01:41
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-27 10:59:44
 * @FilePath: \open-api\src\services\model\user\login.ts
 * @Description: 用户登录
 */

export namespace UserLogin {
	export enum Columns {
		USERNAME = 'username',
		PASSWORD = 'password',
	}
	export interface Post {
		[Columns.USERNAME]: string;
		[Columns.PASSWORD]: string;
	}

	export interface Response {
		access_token: string;
		token_type: string;
		expires_in: number;
		username: string;
		type: string;
		info_required: boolean;
	}
}
