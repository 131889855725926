
import request from '../service';
import {
    examsList, examRoom, application, applications, invoices, invoice, applicationconfirm, examsChangeList, examChangeRoom,
    checkIdUrl, sourceList, sourceDetail, sourceReport, sourceStatus
} from '@api';

// 获取考试列表
export const getEaxamsListAction = (params: any) =>
    request.get(`${examsList}`, { params });

// 获取转考考试列表
export const getChangeEaxamsListAction = (params: any) =>
    request.get(`${examsChangeList}`, { params });
    
// 获取区域
export const getEaxamsRoomsAction = (params: any) =>
    request.get(`${examRoom}/${params?.examId}/city`, {});

export const getexamChangeRoom = (params: any) =>
    request.get(`${examChangeRoom}`, { params });

// 报名确认
export const getExamConfirm = (params: any) =>
    request.get(`${applicationconfirm}?exam_id=${params?.examId}&city_id=${params?.cityId}`, { ...params });

export const checkId = (params: any) =>
request.get(`${checkIdUrl}?exam_id=${params?.examId}`);

// 转考确认
export const applicationChange = (params: any) => {
    const { applicationId, new_city_id, new_exam_id } = params;
    return  request.post(`/application/${applicationId}/change?new_exam_id=${new_exam_id}&new_city_id=${new_city_id}`);
}

// 报名
export const postApplication = (params: any) =>
    request.post(`${application}`, { ...params });
    
// 获取已报名的考试
export const getApplications = (params: any) =>
    request.get(`${applications}`, { params });


//退款
export const putRefund = (params: any) => request.put(`${application}/${params?.applicationId}/cancel`, {});

// 改期
export const putChange = (params: any) => request.put(`${application}/${params?.applicationId}/change`, {});
// 获取考试信息
// export const getApplicationInfo = (params: any) =>
// request.get(`${application}/${params?.applicationId}/user_info`, params.values);

// 更新某个考试的考生信息
export const editApplyPersonalInfo = (params: any) =>
    request.put(`${application}/${params?.applicationId}/examinee_info`, params.values);

// 获取付款信息
export const getApplyInfoByExamId = (params: any) =>
request.get(`${examRoom}/${params?.examId}/application`, params.values);
    // service/exam/2/application

// Request<PersonalEdit.Post, PersonalInfo.Response>
// = params => request.put(personalEdit, params);

// 获取发票列表
export const getInvoices = (params: any) =>
    request.get(`${invoices}`, { params });

// 获取发票详情
export const getInvoicesInfo = (params: any) => request.get(`${invoice}/${params?.applicationId}`, {});


export const updateInvoicesInfo = (params: any) =>
    request.put(`${invoice}/${params?.applicationId}`, params.values);


export const getPaymentInfo = (params: any, cancelToken: any) => {
    return request({
        url: `${application}/${params?.applicationId}/payment`,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params,
        cancelToken
    });
    // return request.put(`${application}/${params?.applicationId}/payment`, {}, {cancelToken: cancelToken});
}

// 获取支付二维码
export const postQrCode = (params: any, cancelToken: any) => {
    const { application_id, ...others } = params;
    // return request.post(`${application}/qr/code?application_id=${application_id}`, { ...others });
    return request({
        url: `${application}/qr/code?application_id=${application_id}`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: others,
        cancelToken
    });
}

// 获取转考支付二维码
export const postChangeQrCode = (params: any, cancelToken: any) => {
    const { application_id, exam_id, city_id, ...others } = params;
    // return request.post(`${application}/qr/code?application_id=${application_id}`, { ...others });
    // https://api-test.chiest.com/toeic-examinee/service/application/change/qr/code?application_id=27
    return request({
        url: `${application}/change/qr/code?application_id=${application_id}&exam_id=${exam_id}&city_id=${city_id}`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: others,
        cancelToken
    });
}

// query/status?application_id=4
// 获取支付状态
export const postPayStatus = (params: any, cancelToken: any) => {
    const { application_id, ...others } = params;
    // return request.post(`${application}/query/status?application_id=${application_id}`, { ...others });
    return request({
        url: `${application}/query/status?application_id=${application_id}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: others,
        cancelToken
    });
}

// 获取成绩列表
export const getScoresListAction = (params: any) => request.get(`${sourceList}`, { params });
// 获取成绩列表
export const getScoreDetailAction = (params: any) => request.get(`${sourceDetail}?application_id=${params?.application_id}`);
export const getsourceReportAction = (params: any) => request.get(`${sourceReport}?application_id=${params?.application_id}`);
export const getSourceStatusAction = (params: any) => request.get(`${sourceStatus}?application_id=${params?.application_id}`);