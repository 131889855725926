/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-29 17:07:24
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-29 17:08:28
 * @FilePath: \application-examinee-frontend\src\request\model\user\personalEdit.ts
 * @Description: 完善个人信息
 */

export namespace PersonalEdit {

    export enum CeritificateEnum {
        ID_CARD = '身份证',
        PASSPORT = '护照',
        HK_PASSPORT = '港澳居民来往内地通行证',
        TW_PASSPORT = '台湾居民来往大陆通行证'
    };

    export enum Columns {
        // 姓名
        NAME = 'name',
        // 性别
        GENDER = 'gender',
        // 国籍
        COUNTRY = 'country',
        // 出生日期
        BIRTH_DATA = 'birth_date',
        // 手机号码
        PHONE = 'phone',
        // 电子邮箱
        EMAIL = 'email',
        // 证件类型
        CERTIFICATE_TYPE = 'certificate_type',
        // 证件号码
        CERTIFICATE_ID = 'certificate_id',
        // 省
        ADDRESS_PROVINCE = 'address_province',
        // 市
        ADDRESS_CITY = 'address_city',
        // 详细地址
        ADDRESS_DETAIL = 'address_detail',
        // 姓
        PINYIN_LAST_NAME = 'pinyin_last_name',
        // 名
        PINYIN_FIRST_NAME = 'pinyin_first_name',
        // 大头照
        PHOTO = 'show_photo',
        SERVICE_PHTOTO = 'photo',
        // 用户id
        ID = 'id',
        CREATED_TIME = 'created_time',
        UPDATED_TIME = 'updated_time',
        // 邮寄地址统称
        ADDRESS = 'address'
    }

    export interface Post {
        [Columns.NAME]: string;
        [Columns.GENDER]: string;
        [Columns.COUNTRY]: string;
        [Columns.BIRTH_DATA]: string;
        [Columns.PHONE]: string;
        [Columns.EMAIL]: string;
        [Columns.CERTIFICATE_TYPE]: string;
        [Columns.CERTIFICATE_ID]: string;
        [Columns.ADDRESS_PROVINCE]: string;
        [Columns.ADDRESS_CITY]: string;
        [Columns.ADDRESS_DETAIL]: string;
        [Columns.PINYIN_LAST_NAME]: string;
        [Columns.PINYIN_FIRST_NAME]: string;
        [Columns.SERVICE_PHTOTO]: string;
        [Columns.ID]: string;
        [Columns.CREATED_TIME]: string;
        [Columns.UPDATED_TIME]: string;
        // temp
        [Columns.ADDRESS]?: {
            province?: string;
            city?: string;
        }
    }

    export interface Response {
       
    }
}