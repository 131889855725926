/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-30 11:01:32
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-30 14:51:02
 * @FilePath: \application-examinee-frontend\src\request\model\user\getbackPsw.ts
 * @Description: 找回密码数据模型
 */

// 找回密码第一步
export namespace GetBackPsw1 {
	export enum Columns {
        // 区分手机注册还是邮箱注册 phone 或 email
		TYPE = 'type',
        // 手机号或者邮箱
		USERNAME = 'username',
        // 图片验证码
        IMAGE_CAPTCHA = 'image_captcha',
        // 手机或邮箱验证码
        PHONE_OR_EMAIL_CAPTCHA = 'phone_or_email_captcha',
	}

	export interface Post {
		[Columns.TYPE]?: 'phone' | 'email';
		[Columns.USERNAME]?: string;
        [Columns.IMAGE_CAPTCHA]?: string;
		[Columns.PHONE_OR_EMAIL_CAPTCHA]?: string;
	}

	export interface Response {
		token: string;
	}
}

// 找回密码第二步
export namespace GetBackPsw2 {
	export enum Columns {
        // 找回密码第一步返回的token
		TOKEN = 'token',
        // 密码
		PASSWORD = 'password',
        // 确认密码
        CONFIRM_PASSWORD = 'confirm_password',
	}

	export interface Post {
		[Columns.TOKEN]?: string;
		[Columns.PASSWORD]?: string;
	}

	export interface Response {
		token: string;
	}
}
