/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-26 14:22:46
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-01-04 10:01:44
 * @FilePath: \application-examinee-frontend\src\configure\routes\index.js
 * @Description: 路由配置导出
 */

import React from 'react';
import { Redirect } from 'react-router-dom';

import dashboardRoutes from './dashboard';
import userRoutes from './user';

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/apply" />,
    },
    ...dashboardRoutes,
    ...userRoutes,
    {
        path: '*',
        component: React.lazy(() => import('@/pages/not-found'))
    }
];

export const blackList = ['/login', '/blog', '/register'];
export default routes;
