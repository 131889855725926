/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-30 10:16:32
 * @LastEditors: WIN-J7OL7MK489U\EDY 13478707150@163.com
 * @LastEditTime: 2023-08-28 11:42:16
 * @FilePath: \application-examinee-frontend\src\pages\home\hooks\useGetBackPsw.tsx
 * @Description: 找回密码弹窗
 */

import React, { useMemo, useState } from 'react';
import moment from 'moment';

import { Modal } from 'antd';

import PersonalViewForm from '@/pages/apply/components/personal-view-form';
import TitleBar from '@/pages/apply/components/title-bar';

import styles from './index.module.less';

export default ({
    onCancel,
    onOk,
    submitTask
}) => {
    const [visible, updateVisible] = useState(false);
    const [initValues, updateInitValues] = useState();

    /**
     * @description: 展示弹窗
     * @return {*}
     */
    const showModal = (values) => {
        values && updateInitValues(initValues);
        updateVisible(true);
    };

    /**
     * @description: 返回
     * @return {*}
     */
    const handleBack = () => {
        initValues && updateInitValues(null);
        updateVisible(false);
        onCancel?.();
    };

    const modalRenderer = useMemo(() => (
        <Modal
            className={styles.container}
            open={visible}
            closable={false}
            onCancel={handleBack}
            footer={false}
            centered
            width={600}
            destroyOnClose
        >
            <div className={styles.container}>
                <TitleBar title='个人信息' />
                <PersonalViewForm />
            </div>
        </Modal>
    ), [visible]);

    return {
        modalRenderer,
        showModal
    };
};
