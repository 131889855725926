/*
 * @Author: JC 13478707150@163.com
 * @Date: 2023-01-07 08:04:51
 * @LastEditors: WIN-J7OL7MK489U\EDY 13478707150@163.com
 * @LastEditTime: 2023-08-28 11:43:25
 * @FilePath: \application-examinee-frontend\src\pages\apply\components\personal-view-form\index.tsx
 * @Description: 个人信息详情
 */

import React, { useEffect } from 'react';
import { map } from 'lodash';
import cls from 'classnames';

import { Form, FormItemProps } from 'antd';

import { PersonalEdit, PersonalInfo } from '@model';
import useUserInfo from '@/hooks/useUserInfo';

import styles from './index.module.less';

type ConfigItemType = FormItemProps & { component?: React.ReactNode };

const Content = ({ value }: { value?: string }) => <div className={styles.control}>{value}</div>;
const Avator = ({ value }: { value?: string }) => <img className={styles.avator} src={value} draggable={false}></img>;

const config: ConfigItemType[] = [
    {
        label: '姓名',
        name: PersonalEdit.Columns.NAME,
        component: <Content />
    },
    {
        label: '性别',
        name: PersonalEdit.Columns.GENDER,
        component: <Content />
    },
    {
        label: '出生日期',
        name: PersonalEdit.Columns.BIRTH_DATA,
        component: <Content />
    },
    {
        label: '手机号码',
        name: PersonalEdit.Columns.PHONE,
        component: <Content />
    },
    {
        label: '电子邮箱',
        name: PersonalEdit.Columns.EMAIL,
        component: <Content />
    },
    {
        label: '证件类型',
        name: PersonalEdit.Columns.CERTIFICATE_TYPE,
        component: <Content />
    },
    {
        label: '证件号码',
        name: PersonalEdit.Columns.CERTIFICATE_ID,
        component: <Content />
    }
];

const PersonalViewForm: React.FC<{ className?: string, r?:any }> = ({ className, r }) => { // r为外面传入的列表
    const [form] = Form.useForm();
    const { user } = useUserInfo();
    const prefix = cls(styles.container, className);

    useEffect(() => {
        const values = {
            ...user,
            name: user?.family_name + user?.given_name,
            pinyin_name: `${user?.pinyin_last_name} ${user?.pinyin_first_name}`,
            address: `${user?.address_province}${user?.address_city}${user?.address_detail}`
        }
        form.setFieldsValue(values);
    }, [user]);


    useEffect(() => {
        if (r) {
            form.setFieldsValue({ 'phone': r.phone})
            form.setFieldsValue({ 'email': r.email})
        }
    }, [r]);

    return (
        <Form
            className={prefix}
            form={form}
            labelAlign='left'
            colon={false}
            requiredMark={false}
            labelCol={{ flex: '80px', className: styles.label }}
        >
            <div className={styles.left}>
                {map(config, (item, colKey) => {
                    const { component, ...itemProps } = item;
                    return (
                        <Form.Item
                            key={colKey}
                            className={styles['form-item']}
                            wrapperCol={{ flex: 1 }}
                            {...itemProps}
                        >
                            {component}
                        </Form.Item>
                    );
                })}
            </div>
            <div className={styles.right}>
                <Form.Item name={PersonalInfo.Columns.PHOTO}>
                    <Avator />
                </Form.Item>
            </div>
        </Form>
    );
};

export default PersonalViewForm;