/**
 * @file 入口文件
 * @author jianghaoran01
 */
import React, {useEffect} from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {store} from './store';
import PrimaryLayout from './layout/primary-layout';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
// import jaJP from 'skd/lib/locale/ja_JP';
import 'moment/locale/zh-cn';

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import zhCN18n from './local-18l/zh_CN';
// import enCN18n from '~src/local/en_CN';

import './App.less';


i18n
    .use(initReactI18next)
    .init({
        resources: {
          zh: zhCN18n,
        },
        lng: 'zh' // 这里是默认语言，也就是初始显示的语言类型
    });

function App() {

  useEffect(() => {
    // document.title = '試験監督システム';
    // document.title = '监考系统';
  }, []);

  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={zhCN}>
          <PrimaryLayout />
        </ConfigProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
