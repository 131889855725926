/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-28 09:39:33
 * @LastEditors: WIN-J7OL7MK489U\EDY 13478707150@163.com
 * @LastEditTime: 2023-08-03 11:51:26
 * @FilePath: \application-examinee-frontend\src\configure\routes\user.ts
 * @Description: 用户相关路由页面
 */

import React from 'react';

const userRoutes = [
    {
        path: '/register',
        exact: true,
        component: React.lazy(() => import('@/pages/register'))
    },
    {
        path: '/personal-info-edit',
        exact: true,
        component: React.lazy(() => import('@/pages/personal-info-edit'))
    },
    {
        path: '/enterprise-very',
        exact: true,
        component: React.lazy(() => import('@/pages/enterprise-very'))
    },
    {
        path: '/password',
        exact: true,
        component: React.lazy(() => import('@/pages/password'))
    }
];

export default userRoutes;
