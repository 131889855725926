/*
 * @Author: jianghaoran01
 * @Date: 2022-12-26 14:22:46
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-01-06 09:58:13
 * @FilePath: \application-examinee-frontend\src\layout\layout-main\index.tsx
 * @Description: 布局主体部分
 */

import React, { Suspense } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Layout, Spin } from 'antd';

import routes from '@/configure/routes';

import styles from './index.module.less';

const Loading = () => <Spin size='large'><div className={styles.loading}></div></Spin>;

const LayoutMain = () => {

    return (
        <Layout className={styles.container}>
            <Switch>
                <Suspense fallback={<Loading />}>
                    {renderRoutes(routes)}
                </Suspense>
            </Switch>
        </Layout>
    );
};

export default withRouter(LayoutMain);