/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-27 10:47:36
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-28 10:21:11
 * @FilePath: \application-examinee-frontend\src\components\Notification\index.tsx
 * @Description: 提示框
 */

import React from 'react';
import {notification as notifincationAntd} from 'antd';
import {CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled} from '@ant-design/icons';

import {FunctionName, NotificationProps} from './interface';
import Message from './message';
import Description from './description';
import './index.less';

const PREFIX = 'notification-hastime';

const handler = {
    get: (_target: any, name: FunctionName) => {
        try {
            let key = new Date().getTime(); // 通过key来强制关闭notification

            return (props: any) => {
                // 定义默认图标
                const iconProps: {icon: React.ReactChild | null} = {
                    icon: null
                };

                switch (name) {
                    case 'success':
                        iconProps.icon = <CheckCircleFilled />;
                        break;
                    case 'error':
                        iconProps.icon = <CloseCircleFilled />;
                        break;
                    case 'warning':
                        iconProps.icon = <ExclamationCircleFilled />;
                        break;
                    default:
                        break;
                }

                const args = {
                    key,
                    ...iconProps,
                    ...props
                };

                // 初始化倒计时
                let tempDuration;
                if (!args.duration) {
                    tempDuration = 3;
                }
                else {
                    tempDuration = args.duration;
                }

                args.duration = 0;

                // 设置样式
                if (props && props.className) {
                    args.className = props.className + ' ' + PREFIX;
                }
                else {
                    args.className = PREFIX;
                }

                if (args.message instanceof ReferenceError) {
                    throw Error('no message');
                }
                // 设置DOM
                args.message = (
                    <Message
                        content={args?.content}
                        message={args && args.message ? args.message : ''}
                        countdown={tempDuration}
                        notificationKey={key}
                    />
                );
                args.description = <Description description={args.description} />;
                return notifincationAntd[name](args);
            };
        }
        catch (err) {
            console.warn(err);
        }
    }
};

/**
 * 代理对象
 * 使用时: target.xxx会全部丢给notification 类似notification.xxx
 */
const target = {};

const notification: NotificationProps = new Proxy(target, handler);

export default notification;
