/*
 * @Author: WIN-J7OL7MK489U\EDY 13478707150@163.com
 * @Date: 2023-08-04 11:33:29
 * @LastEditors: WIN-J7OL7MK489U\EDY 13478707150@163.com
 * @LastEditTime: 2023-08-28 14:26:01
 * @FilePath: \tfj-frontend-application\src\hooks\useLocalRole.ts
 * @Description: 企业/个人角色
 */

import { useMemo } from 'react';
import { useLocalStorageState } from 'ahooks';
import { changeRoleAction } from '@/request/actions';
import notification from '@/components/notification';
import { useHistory } from 'react-router';

export const LocalRoleKey = 'toeic-role';

export type Role = 'personal' | 'enterprise' | '';

export const RoleEnum = {
    personal: {label: '个人账号', value: 1},
    enterprise: {label: '企业账号', value: 2}
};

const useLocalRole = () => {
    const [localRole, updateLocalRole] = useLocalStorageState<Role>(LocalRoleKey, { defaultValue: '' });
    const defaultVal = JSON.parse(localStorage.getItem(LocalRoleKey) || '""');
    const history = useHistory();

    const roleTaskPool = useMemo(() => ({
        personal: {
            label: `切换到${RoleEnum.enterprise.label}`,
            task: async ( showMsg = true) => {
                await changeRoleAction({ login_role: RoleEnum.personal.value });
                updateLocalRole('enterprise');
                showMsg && notification.success({message: `已切换到${RoleEnum.enterprise.label}`});
                history.replace('/');
            }
        },
        enterprise: {
            label: `切换到${RoleEnum.personal.label}`,
            task: async (showMsg = true) => {
                await changeRoleAction({ login_role: RoleEnum.enterprise.value });
                updateLocalRole('personal');
                showMsg && notification.success({message: `已切换到${RoleEnum.personal.label}`});
                history.replace('/');
            }
        },
        '': { label: '-', task: () => { } }
    }), []);

    return {
        localRole: localRole || defaultVal as Role,
        updateLocalRole,
        roleTaskPool
    };
};

export default useLocalRole;
