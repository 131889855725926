/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-27 10:36:35
 * @LastEditors: WIN-J7OL7MK489U\EDY 13478707150@163.com
 * @LastEditTime: 2023-08-28 10:34:32
 * @FilePath: \application-examinee-frontend\src\request\actions\user.ts
 * @Description: 用户登录请求方法定义
 */

import qs from 'qs';
import request, { AnyRequest, Request } from '../service';
import {
	userLogin,
	personalInfo,
	register,
	imageCaptcha,
	phoneCaptcha,
	emailCaptcha,
	personalEdit,
	pswGetBack1,
	pswGetBack2,
	pswReset,
	pwPhoneCaptcha,
	pwEmailCaptcha,
	putExamineeInfoApi,
	enterpriseEmailCaptchaApi,
	enterpriseValidateApi,
	enterpriseCodeVeryApi,
	changeRoleApi
} from '@api';
import {
	UserLogin,
	PersonalInfo,
	Register,
	ImageCaptcha,
	PhoneCaptcha,
	EmailCaptcha,
	PersonalEdit,
	GetBackPsw1,
	GetBackPsw2,
	ResetPsw,
	PwPhoneCaptcha,
	PwEmailCaptcha
} from '@model';

// 登录
export const loginAction: Request<UserLogin.Post, UserLogin.Response>
	= params => request.post(userLogin, qs.stringify(params), {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});

// 获取考生信息
export const getPersonalInfo: Request<PersonalInfo.Post, PersonalInfo.Response>
	= params => request.get(personalInfo, { params });

// 考生注册
export const registerExaminee: Request<Register.Post, Register.Response>
	= params => request.post(register, params);

// 获取图片验证码
export const getImageCaptcha: Request<ImageCaptcha.Post, ImageCaptcha.Response>
	= params => request.get(imageCaptcha, { params, responseType: 'blob' });

// 获取手机验证码
export const getPhoneCaptcha: Request<PhoneCaptcha.Post, PhoneCaptcha.Response>
	= params => request.post(phoneCaptcha, params);

// 获取邮箱验证码
export const getEmailCaptcha: Request<EmailCaptcha.Post, EmailCaptcha.Response>
	= params => request.post(emailCaptcha, params);

// 完善考生信息
export const editPersonalInfo: Request<PersonalEdit.Post, PersonalInfo.Response>
	= params => request.put(personalEdit, params);

// 找回密码第一步
export const getBackPsw1: Request<GetBackPsw1.Post, GetBackPsw1.Response>
	= params => request.put(pswGetBack1, params);

// 找回密码第二步
export const getBackPsw2: Request<GetBackPsw2.Post, GetBackPsw2.Response>
	= params => request.put(pswGetBack2, params);

// 重置密码
export const resetPsw: Request<ResetPsw.Post, ResetPsw.Response>
	= params => request.put(pswReset, params);

// 找回密码获取手机验证码
export const getPwPhoneCaptcha: Request<PwPhoneCaptcha.Post, PwPhoneCaptcha.Response>
	= params => request.post(pwPhoneCaptcha, params);

// 找回密码获取邮箱验证码
export const getPwEmailCaptcha: Request<PwEmailCaptcha.Post, PwEmailCaptcha.Response>
	= params => request.post(pwEmailCaptcha, params);

// 考生完善信息
export const putExamineeInfoAction: AnyRequest
	= params => request.put(putExamineeInfoApi, params);

// 企业标识码验证
export const enterpriseCodeVeryAction: AnyRequest
	= params => request.get(enterpriseCodeVeryApi, { params });

// 获取企业邮箱验证码
export const enterpriseEmailCaptchaAction: AnyRequest
	= params => request.post(enterpriseEmailCaptchaApi, params);

// 企业邮箱验证
export const enterpriseValidateAction: AnyRequest
	= params => request.post(enterpriseValidateApi, params);
	
// 切换企业&个人账号
export const changeRoleAction: AnyRequest
	= params => request.post(changeRoleApi, params);
