/**
 * @file 报名store
 * @author jianghaoran01
 */
import {createSlice} from '@reduxjs/toolkit';
// import {author} from '@request/author';
// import request, {post} from '@request/service';

const initialState = {
    applySteps: 0, // 报名步骤
    exam: undefined, 
    examId: undefined, // 未报名成功前的考试ID 如果为undefined不能进行下一步(0 - 1)
    examAreaP: undefined, // 显示用
    examArea: undefined, // 未报名成功前的考试区域ID 如果为undefined不能进行下一步(0 - 1)
    applicationId: undefined, // 报名后的ID
    readMe: false, // 需看完报名须知进行下一步
    imgUrl: '',
    imgShowUrl: '',
    rd: null, // 跳转传入的参数
};

export const applySlice = createSlice({
    name: 'applySlice',
    initialState,
    reducers: {
        updateApplySteps: (state, action) => {
            state.applySteps = action.payload
        },
        updateExam: (state, action) => {
            state.exam= action.payload
        },
        updateExamId: (state, action) => {
            state.examId = action.payload
        },
        updateReadMe: (state, action) => {
            state.readMe = action.payload
        },
        updateExamAreaP: (state, action) => {
            state.examAreaP = action.payload
        },
        updateExamArea: (state, action) => {
            state.examArea = action.payload
        },
        updateAppliationId: (state, action) => {
            state.applicationId = action.payload
        },
        updateImgUrl: (state, action) => {
            state.imgUrl = action.payload
        },
        updateImgShowUrl: (state, action) => {
            state.imgShowUrl = action.payload
        },
        updateRD: (state, action) => {
            state.rd = action.payload
        },
    }
});

export const apply = state => state.applySlice;

export const {updateApplySteps, updateExam, updateExamId, updateExamAreaP, updateExamArea, updateReadMe, updateAppliationId, updateImgUrl, updateImgShowUrl, updateRD} = applySlice.actions;
