/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-27 15:03:14
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-30 15:30:55
 * @FilePath: \application-examinee-frontend\src\request\actions\blog.ts
 * @Description: 博客请求方法导出
 */

import request, { Request } from '../service';
import { blogList, blogDetail } from '@api';
import { BlogList, BlogDetail } from '@model';

// 获取博客列表
export const getBlogList: Request<BlogList.Post, BlogList.Response>
	= params => request.get(blogList, { params });

// 获取博客详情
export const getBlogDetail: Request<BlogDetail.Post, BlogDetail.Response>
	= params => request.get(`${blogDetail}/${params.blog_id}`, { params });
