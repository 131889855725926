/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-27 15:02:32
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-30 15:30:30
 * @FilePath: \application-examinee-frontend\src\request\api\blog.ts
 * @Description: 博客api导出
 */

export const blogList = '/blogs';

export const blogDetail = '/blog';
