/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-28 13:54:00
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2022-12-28 13:58:36
 * @FilePath: \application-examinee-frontend\src\request\model\user\register.ts
 * @Description: 考生注册接口数据模型
 */

export namespace Register {
	export enum Columns {
        // 区分手机注册还是邮箱注册 phone 或 email
		TYPE = 'type',
        // 手机号或者邮箱
		USERNAME = 'username',
        // 图片验证码
        IMAGE_CAPTCHA = 'image_captcha',
        // 手机或邮箱验证码
        PHONE_OR_EMAIL_CAPTCHA = 'phone_or_email_captcha',
        // 密码
        PASSWORD = 'password',
        // 确认密码
        CONFIRM_PASSWORD = 'confirm_password'
	}

	export interface Post {
		[Columns.TYPE]?: 'phone' | 'email';
		[Columns.USERNAME]?: string;
        [Columns.IMAGE_CAPTCHA]?: string;
		[Columns.PHONE_OR_EMAIL_CAPTCHA]?: string;
        [Columns.PASSWORD]?: string;
	}

	export interface Response {
		type: string;
		username: string;
		id: number;
		created_time: string;
		updated_time: string;
	}
}
