
export const examsList = '/exams'; // 查询考试列表
export const examRoom = ''; // 查询考试区域
export const application = '/application'; // 报名
export const applicationconfirm = '/application/confirm'; // 报名queren
export const applications = '/applications'; // 获取报名信息
export const invoices = '/invoices'; // 发票
export const invoice = '/invoice';
export const refund = '/refund';
export const examsChangeList = '/application/change/exams'
export const examChangeRoom = '/application/change/cities'
export const toeicExaminee = '/toeic-examinee/service/application'
export const sourceList = '/examinee/scores'
export const sourceDetail = '/examinee/score/detail'
export const sourceReport = '/examinee/score/report'
export const sourceStatus = '/examinee/score/status'
export const checkIdUrl = '/application/cert/list'