/*
 * @Author: JC 13478707150@163.com
 * @Date: 2023-01-04 13:47:29
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-01-04 13:48:47
 * @FilePath: \application-examinee-frontend\src\request\model\user\resetPsw.ts
 * @Description: 重置密码
 */

export namespace ResetPsw {
    export enum Columns {
        // 旧密码
        OLD_PASSWORD = 'old_password',
        // 新密码
        NEW_PASSWORD = 'new_password',
        // 确认密码
        CONFIRM_PASSWORD = 'image_captcha',
    }

    export interface Post {
        [Columns.OLD_PASSWORD]?: string;
        [Columns.NEW_PASSWORD]?: string;
    }

    export interface Response {
        token: string;
    }
}
