/*
 * @Author: JC 13478707150@163.com
 * @Date: 2022-12-27 10:36:35
 * @LastEditors: WIN-J7OL7MK489U\EDY 13478707150@163.com
 * @LastEditTime: 2023-08-28 10:33:59
 * @FilePath: \application-examinee-frontend\src\request\api\user.ts
 * @Description: 用户登录接口定义
 */

// 登录
export const userLogin = '/token';
// 获取用户信息
export const personalInfo = '/examinee_info';
// 注册
export const register = '/examinee';
// 图片验证码
export const imageCaptcha = '/image_captcha';
// 手机验证码
export const phoneCaptcha = '/phone_captcha';
// 找回密码的手机验证码
export const pwPhoneCaptcha = '/pw_phone_captcha';
// 邮箱验证码
export const emailCaptcha = '/email_captcha';
// 找回密码的邮箱验证码
export const pwEmailCaptcha = '/pw_email_captcha';
// 文件上传
export const uploadFile = '/file';
// 完善用户信息
export const personalEdit = '/examinee_info';
// 找回密码第一步
export const pswGetBack1 = '/examinee/retrieve_password/1';
// 找回密码第二部
export const pswGetBack2 = '/examinee/retrieve_password/2';
// 重置密码
export const pswReset = '/examinee/change_password';

// 完善用户信息
export const putExamineeInfoApi = '/examinee_info';
// 企业标识码验证
export const enterpriseCodeVeryApi = '/enterprise/code/validate';
// 获取企业邮箱验证码
export const enterpriseEmailCaptchaApi = '/enterprise_email_captcha';
// 企业邮箱验证
export const enterpriseValidateApi = '/enterprise/email/validate';
// 切换企业&个人账号
export const changeRoleApi = '/change/login/role';